import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import "./AdditionalInfoForm.scss";

const AdditionalInfoForm = () => {
  const { state } = useLocation(); // Access the passed state (e.g., userName)
  const { userName } = state || {};
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState({
    username: userName || "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companyStrength: "",
    officialEmail: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const validatePasswordStrength = (password) => {
    const regex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Check password strength
    if (name === "password") {
      if (!validatePasswordStrength(value)) {
        setPasswordError(
          "Password must be at least 8 characters long, contain an uppercase letter, a number, and a special character."
        );
      } else if (
        formData.confirmPassword &&
        value !== formData.confirmPassword
      ) {
        setPasswordError("Passwords do not match.");
      } else {
        setPasswordError("");
      }
    }

    // Check if passwords match
    if (name === "confirmPassword") {
      if (value !== formData.password) {
        setPasswordError("Passwords do not match.");
      } else {
        setPasswordError("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_BACKEND_URL}/update/user-details`,
        formData
      );

      if (response.status === 200) {
        navigate("/plans");
        setSuccessMessage("Details saved successfully!");
      } else {
        console.error("Error saving details:", response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="additionalInfo-form">
      {successMessage && <p className="success">{successMessage}</p>}

      <h1>Welcome New User! Please fill in these details.</h1>
      <div>
        <label>Username:</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>Password:</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        {passwordError && <p className="error">{passwordError}</p>}
      </div>

      <div>
        <label>Confirm Password:</label>
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
        {passwordError && <p className="error">{passwordError}</p>}
      </div>

      <div>
        <label>Company Name:</label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Company Strength:</label>
        <input
          type="number"
          name="companyStrength"
          value={formData.companyStrength}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Official Email:</label>
        <input
          type="email"
          name="officialEmail"
          value={formData.officialEmail}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default AdditionalInfoForm;
