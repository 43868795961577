import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { Icon } from "@iconify/react";
import "./Footer.scss"; // Import the SCSS file

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Social Media Icons */}
        <div className="footer-social">
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <Icon icon="mdi:twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/hrlabss/posts/?feedView=all"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <Icon icon="mdi:linkedin" />
          </a>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <Icon icon="mdi:facebook" />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <Icon icon="mdi:instagram" />
          </a>
          <a
            href="https://youtube.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <Icon icon="mdi:youtube" />
          </a>
        </div>

        {/* Footer Links */}
        <div className="footer-links">
          <div className="footer-column">
            <h4>Explore</h4>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>{" "}
                {/* Changed <a> to <Link> */}
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Information</h4>
            <ul>
              <li>
                <Link to="/plans">Membership</Link>{" "}
                {/* Changed <a> to <Link> */}
              </li>
              <li>
                <Link to="/terms-and-condition/2">Privacy Policy</Link>{" "}
                {/* Changed <a> to <Link> */}
              </li>
              <li>
                <Link to="/terms-and-condition/1">Terms and Conditions</Link>{" "}
                {/* Changed <a> to <Link> */}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Footer Copyright */}
      <div className="footer-copyright">
        <p>
          &copy; {new Date().getFullYear()} OurDept.com | All Rights Reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;
