import React from "react";
import "./TermsAndCondition.scss";

function TermsAndCondition() {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Terms and Conditions</h1>
      <div className="terms-section">
        <h2 className="terms-subtitle">Introduction</h2>
        <p className="terms-text">
          These Terms and Conditions govern your access to and use of all
          software products, services, and resources provided by OurDept.com. By
          accessing our website or purchasing our products, you agree to comply
          with these terms. We reserve the right to modify or update these terms
          at any time.
        </p>
      </div>
      <div className="terms-section">
        <h2 className="terms-subtitle">User Responsibility</h2>
        <p className="terms-text">
          You agree to provide accurate and up-to-date information when using
          our services. You are responsible for maintaining the confidentiality
          of your account and for all activities under your account.
        </p>
      </div>
      <div className="terms-section">
        <h2 className="terms-subtitle">Licensing and Usage</h2>
        <p className="terms-text">
          Upon purchasing our software products, you are granted a
          non-transferable, non-exclusive license to use the software. You are
          prohibited from distributing, copying, or reverse-engineering the
          software unless specified in the user agreement.
        </p>
      </div>
      <div className="terms-section">
        <h2 className="terms-subtitle">Payment Terms</h2>
        <p className="terms-text">
          All prices are listed in [INR], and payment is required before access
          to the software is granted. We accept various payment methods,
          including credit cards, PayPal, and other secure payment gateways.
        </p>
      </div>
      <div className="terms-section">
        <h2 className="terms-subtitle">Prohibited Activities</h2>
        <p className="terms-text">
          You agree not to engage in any activity that could harm, disrupt, or
          impair the website or the software’s operation. This includes
          unauthorized access, hacking, and distributing malware.
        </p>
      </div>
      <div className="terms-section">
        <h2 className="terms-subtitle">Limitation of Liability</h2>
        <p className="terms-text">
          We are not liable for any indirect, special, incidental, or
          consequential damages arising from the use of our products or
          services. Our total liability is limited to the amount you paid for
          the software.
        </p>
      </div>
      <div className="terms-section">
        <h2 className="terms-subtitle">Governing Law</h2>
        <p className="terms-text">
          These Terms and Conditions shall be governed by the laws of [INDIA],
          and any disputes will be resolved in the competent courts of [INDIA].
        </p>
      </div>
    </div>
  );
}

export default TermsAndCondition;
