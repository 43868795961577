import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Modules from "./Pages/Modules";
import Login from "./Pages/Login";
import Plans from "./Pages/Plans";
import Navbar from "./Components/NavBar/Navbar";
import "./App.scss";
import Signup from "./Pages/Signup";
import ScrollToTop from "./Pages/ScrollToTop";
import Footer from "./Components/Footer/Footer";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndCondition from "./Pages/TermsAndCondition";
import AdditionalInfoForm from "./Pages/AdditionalInfoForm";

function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/modules" element={<Modules />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/plans" element={<Plans />} />
          <Route
            path="/terms-and-condition/1"
            element={<TermsAndCondition />}
          />
          <Route path="/additional-info" element={<AdditionalInfoForm />} />
          <Route path="/terms-and-condition/2" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
