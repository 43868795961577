import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonWithIcon from "../Components/ButtonwithIcon/ButtonWithIcon"; // Import the ButtonWithIcon component
import "./Modules.scss";

const Modules = () => {
  const navigate = useNavigate();

  // Hardcoded module data
  const hardcodedModules = [
    {
      moduleName: "Attendance Management",
      moduleDescription: "Track and manage employee attendance efficiently.",
      moduleFeatures: [
        {
          featureName: "Quick Punch In/Out Menus",
          featureDescription:
            "Allow employees to quickly punch in and out from the system.",
        },
        {
          featureName: "Submit Timesheet with Attendance",
          featureDescription:
            "Enable employees to submit their timesheets along with attendance records.",
        },
        {
          featureName: "Timesheet Submission Restrictions",
          featureDescription:
            "Provide flexibility to configure restrictions for timesheet submissions.",
        },
      ],
    },
    {
      moduleName: "Leave Management",
      moduleDescription: "Streamline leave requests and approvals.",
      moduleFeatures: [
        {
          featureName: "Manage Leave with Approvals",
          featureDescription:
            "Facilitate a seamless leave request and approval workflow.",
        },
        {
          featureName: "Fallback Feature",
          featureDescription:
            "Enable fallback options for leave management in case of emergencies.",
        },
        {
          featureName: "Leave Reports",
          featureDescription:
            "Generate detailed reports on leave usage and trends.",
        },
        {
          featureName: "Leave Actions",
          featureDescription:
            "Approve, cancel, reject, or revoke leave requests efficiently.",
        },
      ],
    },
  ];

  const imageUrls = [
    // "https://hrlabs.in/static/media/newimg6.cd57e9969b8a5b31be7c.png",
    "https://hrlabs.in/static/media/newimg7.334e138c9207cc387990.png",
    "https://hrlabs.in/static/media/newimg8.929e7fd531a7fafe6e93.png",
    "https://hrlabs.in/static/media/newimg9.50d56edff1d96e17dd91.png",
    "https://hrlabs.in/static/media/newimg10.0816cea6fa3899253f65.png",
  ];

  const handleBuyNow = () => {
    navigate("/plans");
  };

  return (
    <div className="modules-container">
      {hardcodedModules.length > 0 ? (
        hardcodedModules.map((module, index) => (
          <section
            key={index}
            className={`module-section ${
              index % 2 === 0 ? "layout-left" : "layout-right"
            }`}
          >
            <div className="module-image">
              <img
                src={imageUrls[index % imageUrls.length]}
                alt={module.moduleName}
              />
            </div>
            <div className="module-content">
              <h2 className="module-name">{module.moduleName}</h2>
              <p className="module-description">{module.moduleDescription}</p>
              {module.moduleFeatures?.length > 0 && (
                <div className="module-features">
                  <h3>Features:</h3>
                  <ul>
                    {module.moduleFeatures.map((feature, idx) => (
                      <li key={idx}>
                        <strong>{feature.featureName}:</strong>{" "}
                        {feature.featureDescription}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="module-footer">
                <ButtonWithIcon
                  icon={"nimbus:shopping-cart"}
                  text="Buy Now"
                  onClick={handleBuyNow}
                />
              </div>
            </div>
          </section>
        ))
      ) : (
        <div className="no-modules">
          <p>No modules available at the moment.</p>
        </div>
      )}
    </div>
  );
};

export default Modules;
