import React from "react";
import "./AboutUs.scss";

function AboutUs() {
  return (
    <div className="about-us-container">
      {/* Hero Section */}
      <section className="about-us-hero">
        <div className="hero-content">
          <h1>About Our Company</h1>
          <p>
            We are a forward-thinking company committed to providing top-notch
            solutions that empower businesses to thrive in a digital world.
          </p>
        </div>
      </section>

      {/* Mission Section */}
      <section className="about-us-section">
        <div className="section-image">
          <img
            src="https://hrlabs.in/static/media/newimg8.929e7fd531a7fafe6e93.png"
            alt="Our Mission"
          />
        </div>
        <div className="section-content">
          <h2>Our Mission</h2>
          <p>
            Our mission is to deliver cutting-edge software solutions that meet
            the evolving needs of businesses. We focus on creating
            user-friendly, scalable, and customizable tools that enhance
            productivity.
          </p>
        </div>
      </section>

      {/* Values Section */}
      <section className="about-us-section reverse">
        <div className="section-content">
          <h2>Our Values</h2>
          <ul>
            <li>
              <strong>Innovation:</strong> Continuously innovating to stay ahead
              of industry trends.
            </li>
            <li>
              <strong>Customer-Centricity:</strong> Understanding customer needs
              and exceeding expectations.
            </li>
            <li>
              <strong>Integrity:</strong> Operating with honesty and ethics.
            </li>
            <li>
              <strong>Excellence:</strong> Delivering excellence in all business
              aspects.
            </li>
          </ul>
        </div>
        <div className="section-image">
          <img
            src="https://hrlabs.in/static/media/newimg9.50d56edff1d96e17dd91.png"
            alt="Our Values"
          />
        </div>
      </section>

      {/* Team Section */}
      <section className="about-us-section">
        <div className="section-image">
          <img
            src="https://hrlabs.in/static/media/newimg10.0816cea6fa3899253f65.png"
            alt="Our Team"
          />
        </div>
        <div className="section-content">
          <h2>Our Team</h2>
          <p>
            Our team is composed of passionate professionals with diverse
            expertise in technology, design, and business. We collaborate to
            deliver exceptional products and services.
          </p>
        </div>
      </section>

      {/* Contact Section */}
      <section className="about-us-contact">
        <h2>Contact Us</h2>
        <p>
          9742194666 <br />
          muthu.kumar@hrlabs.in
          <br />
          4th Main Kalyanagar, Nagarbhavi road, Vijaynagar extention,
          Bengaluru-560048
        </p>
      </section>
    </div>
  );
}

export default AboutUs;
