import React, { useState } from "react";
import { FaSearch, FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../Images/CompanyLogo.png";
import "./Navbar.scss";

const Navbar = () => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  // Check if JWT token exists in sessionStorage
  const jwtToken = sessionStorage.getItem("jwtToken");

  // Updated routes array with unique identifiers
  const routes = [
    { id: 1, path: "/", label: "Home" },
    { id: 2, path: "/about-us", label: "About Us" },
    { id: 3, path: "/modules", label: "Modules" },
    { id: 4, path: "/plans", label: "Plans" },
    { id: 5, path: "/login", label: "Login" },
    { id: 6, path: "/modules", label: "Attendance Module" },
    { id: 7, path: "/modules", label: "Email Automation" },
    { id: 8, path: "/modules", label: "Recruitment" },
    { id: 9, path: "/sign-up", label: "SignUp" },
  ];

  const handleSearchChange = (e) => {
    const query = e.target.value; // Keep the case of the input as is
    setSearchQuery(query);
    if (query) {
      const filtered = routes.filter(
        (route) => route.label.toLowerCase().includes(query.toLowerCase()) // Case-insensitive search
      );
      setFilteredRoutes(filtered);
    } else {
      setFilteredRoutes([]);
    }
  };

  const handleSearchClick = (path) => {
    // Navigate to the selected path
    navigate(path);
    setSearchQuery("");
    setFilteredRoutes([]);
    setIsSearchExpanded(false);
  };

  const toggleSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
    if (!isSearchExpanded) {
      // Clear the search query when expanding the search
      setSearchQuery("");
      setFilteredRoutes([]);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleLogout = () => {
    try {
      if (sessionStorage.getItem("jwtToken")) {
        sessionStorage.removeItem("jwtToken");
      }
      // Redirect to login page instead of reloading the page
      window.location.href = "/login"; // Change "/login" to your login page path
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  return (
    <nav aria-label="Main navigation" className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className={`navbar-menu ${isMenuOpen ? "open" : ""}`}>
        <div className="navbar-close" onClick={closeMenu}>
          <FaTimes />
        </div>
        <div
          className={`search-container ${isSearchExpanded ? "expanded" : ""}`}
        >
          <input
            type="text"
            className="search-input"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            onFocus={() => setIsSearchExpanded(true)}
            onBlur={() => !isSearchExpanded && setIsSearchExpanded(false)}
          />
          <FaSearch
            className="search-icon"
            onClick={toggleSearch} // Add click handler to the search icon
          />
          {isSearchExpanded && filteredRoutes.length > 0 && (
            <div className="search-popup">
              {filteredRoutes.map((route) => (
                <div
                  key={route.id}
                  className="search-result"
                  onMouseDown={() => handleSearchClick(route.path)}
                >
                  {route.label}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="navbar-buttons">
          <Link to="/about-us">
            <button
              className={`navbar-button ${getActiveClass("/about-us")}`}
              onClick={closeMenu}
            >
              About Us
            </button>
          </Link>
          <Link to="/modules">
            <button
              className={`navbar-button ${getActiveClass("/modules")}`}
              onClick={closeMenu}
            >
              Modules
            </button>
          </Link>

          {/* Always render Plans */}
          {jwtToken && (
            <Link to="/plans">
              <button
                className={`navbar-button ${getActiveClass("/plans")}`}
                onClick={closeMenu}
              >
                Plans
              </button>
            </Link>
          )}

          {!jwtToken && (
            <>
              <Link to="/login">
                <button
                  className={`navbar-button ${getActiveClass("/login")}`}
                  onClick={closeMenu}
                >
                  Login
                </button>
              </Link>
              <Link to="/sign-up">
                <button
                  className={`signup-button ${getActiveClass("/sign-up")}`}
                  onClick={closeMenu}
                >
                  Signup
                </button>
              </Link>
            </>
          )}

          {jwtToken && (
            <button className="navbar-button" onClick={handleLogout}>
              Logout
            </button>
          )}
        </div>
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;
