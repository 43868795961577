import axios from "axios";

// Create Axios instance with base URL and credentials
const axiosInstance = axios.create({
  baseURL: "http://localhost:8080/api",
  withCredentials: true, // Ensures cookies are sent with requests
});

// Function to get XSRF token from cookies
const getXsrfTokenFromCookie = () => {
  const match = document.cookie.match(/XSRF-TOKEN=([^;]+)/);
  return match ? match[1] : null;
};

// Function to get XSRF token from session storage
const getXsrfTokenFromSessionStorage = () => {
  return sessionStorage.getItem("xsrfToken");
};

// Function to set the XSRF token in session storage
const setXsrfTokenInSessionStorage = (token) => {
  sessionStorage.setItem("xsrfToken", token);
};

// Request interceptor to attach tokens to request headers
axiosInstance.interceptors.request.use(
  (config) => {
    // Get JWT token from session storage
    const jwtToken = sessionStorage.getItem("jwtToken");

    // Try to get XSRF token from session storage, fallback to cookie if not present
    const xsrfToken =
      getXsrfTokenFromSessionStorage() || getXsrfTokenFromCookie();

    // Add JWT token to the Authorization header in "Bearer <token>" format
    if (jwtToken) {
      config.headers["Authorization"] = jwtToken;
    }

    // Add XSRF token to the X-XSRF-TOKEN header
    if (xsrfToken) {
      config.headers["X-XSRF-TOKEN"] = xsrfToken;
    }

    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle new XSRF tokens
axiosInstance.interceptors.response.use(
  (response) => {
    // Check for new XSRF token in response headers
    const newXsrfToken = response.headers["x-xsrf-token"];
    if (newXsrfToken) {
      setXsrfTokenInSessionStorage(newXsrfToken);
    }
    return response;
  },
  (error) => {
    console.error("Response error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
