import React, { useState } from "react";
import "./Coupon.scss";
import axiosInstance from "../axiosInstance";

const Coupon = ({ planDetails }) => {
  const [couponCode, setCouponCode] = useState("");
  const [validDurations, setValidDurations] = useState({}); // Store valid durations and their discounts
  const [selectedDuration, setSelectedDuration] = useState(3); // Default to 3 months
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [selectedModules, setSelectedModules] = useState([
    { id: 1, name: "Leave Management" },
    { id: 2, name: "Attendance Management" },
  ]);

  const durationLabels = {
    3: "3 Months",
    6: "6 Months",
    12: "12 Months",
  };

  const { planName, totalPrice, employeeRange } = planDetails;

  // Calculate total price for the selected duration
  const getTotalPriceForDuration = (months) => {
    let price = totalPrice * months;
    return price;
  };

  // Handle coupon code validation for each duration
  const validateCoupon = async () => {
    if (!couponCode) {
      setValidDurations({});
      return;
    }

    const monthPlanTypes = {
      3: "THREE_MONTHS",
      6: "SIX_MONTHS",
      12: "TWELVE_MONTHS",
    };

    const validResponse = {};

    for (const duration in monthPlanTypes) {
      try {
        const response = await axiosInstance.post(
          "/discount-plans/validate-coupon",
          {
            monthPlanType: monthPlanTypes[duration],
            moduleIds: selectedModules.map((module) => module.id),
            cuponCode: couponCode,
          }
        );

        if (response.status === 200) {
          validResponse[duration] = {
            isValid: true,
            discount: response.data.data.discountPercentage,
          };
        } else {
          validResponse[duration] = { isValid: false, discount: 0 };
        }
      } catch (error) {
        console.error("Error validating coupon", error);
        validResponse[duration] = { isValid: false, discount: 0 };
      }
    }

    setValidDurations(validResponse);
  };

  return (
    <div className="coupon-container">
      <div className="card-container">
        {[3, 6, 12].map((duration) => {
          const durationPrice = getTotalPriceForDuration(duration);

          // For 12 months, apply a 10% discount regardless of coupon validity
          const isDiscountedFor12Months = duration === 12;
          const discountedPrice = isDiscountedFor12Months
            ? durationPrice * 0.9 // Always apply 10% discount for 12 months
            : validDurations[duration]?.isValid
            ? durationPrice * (1 - validDurations[duration].discount / 100)
            : durationPrice;

          const handleSelection = () => {
            setSelectedDuration(duration);
            setSelectedPlanDetails({
              planName,
              duration: durationLabels[duration],
              modules: selectedModules.map((module) => module.name).join(", "),
              employeeRange,
              price: discountedPrice,
            });
          };

          return (
            <div
              key={duration}
              className={`card ${
                selectedDuration === duration ? "selected" : ""
              }`}
              onClick={handleSelection}
            >
              {(validDurations[duration]?.isValid ||
                isDiscountedFor12Months) && (
                <p className="discount-badge">
                  <strong>Discount:</strong>{" "}
                  {isDiscountedFor12Months
                    ? 10
                    : validDurations[duration]?.discount}
                  % off
                </p>
              )}
              <h3 className="card-title">{durationLabels[duration]}</h3>
              <p className="summary-item">
                {validDurations[duration]?.isValid ||
                isDiscountedFor12Months ? (
                  <>
                    <span className="original-price">
                      ₹{durationPrice.toFixed(2)}
                    </span>
                    <span className="discounted-price">
                      ₹{discountedPrice.toFixed(2)}
                    </span>
                  </>
                ) : (
                  <>
                    <strong>Total Price:</strong> ₹{durationPrice.toFixed(2)}
                  </>
                )}
              </p>
            </div>
          );
        })}
      </div>

      <div className="discount-section">
        <div className="coupon-input-container">
          <input
            type="text"
            placeholder="Enter coupon code"
            className="coupon-input"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <button className="validate-button" onClick={validateCoupon}>
            Validate
          </button>
        </div>

        {Object.values(validDurations).some((item) => item.isValid) &&
          couponCode && (
            <p className="valid-message">Coupon code applied successfully!</p>
          )}
        {Object.values(validDurations).every((item) => !item.isValid) &&
          couponCode && (
            <p className="invalid-message">Invalid coupon code. Try again.</p>
          )}

        {selectedPlanDetails && (
          <div className="discount-summary">
            <h4>Selected Plan Details:</h4>
            <p>
              <strong>Plan:</strong> {selectedPlanDetails.planName} (
              {selectedPlanDetails.duration})
            </p>
            <p>
              <strong>Modules:</strong> {selectedPlanDetails.modules}
            </p>
            <p>
              <strong>Employee Range:</strong>{" "}
              {selectedPlanDetails.employeeRange}
            </p>
            <p>
              <strong>
                Price:{" "}
                {validDurations[selectedDuration]?.isValid ? (
                  <>
                    <span className="discounted-price">
                      ₹{selectedPlanDetails.price.toFixed(2)}
                    </span>
                  </>
                ) : (
                  `₹${selectedPlanDetails.price.toFixed(2)}`
                )}
              </strong>
            </p>
          </div>
        )}
        <button className="proceed-button">Proceed To Checkout</button>
      </div>
    </div>
  );
};

export default Coupon;
