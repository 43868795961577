import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonWithIcon from "../Components/ButtonwithIcon/ButtonWithIcon";
import ColoredCheckbox from "../Components/ColoredCheckbox/ColoredCheckbox";
import "./Login.scss";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axiosInstance from "../axiosInstance";
import Cookies from "js-cookie"; // Import js-cookie for managing cookies
import { ErrorMessage } from "formik";

export default function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState("");
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = Cookies.get("username");
    const storedPassword = Cookies.get("password");

    if (storedUsername && storedPassword) {
      setUsername(storedUsername);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      setError("Username and Password are required");
      return;
    }

    const credentials = `${username}:${password}`;
    const token = btoa(credentials);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/user-login`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || "Login failed");
      }

      const data = await response.json();
      if (data.status === "OK") {
        // Store JWT token and additional details (username and companyName) in sessionStorage
        sessionStorage.setItem("jwtToken", data.jwtToken);
        sessionStorage.setItem("userName", data.userName); // Store username
        sessionStorage.setItem("companyName", data.companyName || ""); // Store company name if available

        if (rememberMe) {
          // Securely store credentials in cookies with a 30-day expiration
          Cookies.set("username", username, {
            expires: 30,
            secure: true,
            sameSite: "Strict",
          });
          Cookies.set("password", password, {
            expires: 30,
            secure: true,
            sameSite: "Strict",
          });
        } else {
          // Remove cookies when not remembered
          Cookies.remove("username");
          Cookies.remove("password");
        }

        navigate("/plans");
      } else {
        setError(data.message || "Invalid username or password");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Login failed, please try again later.");
    }
  };

  const sendGoogleDataToBackend = async (tokenId) => {
    try {
      // Send tokenId as part of the POST request body
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
        { tokenId }
      );

      // Handle successful response
      if (response.status === 200) {
        const { newUser, jwtToken, userName } = response.data.data;

        console.log("Google signup successful", response.data);

        // Store jwtToken and userName in sessionStorage
        sessionStorage.setItem("jwtToken", jwtToken);
        sessionStorage.setItem("userName", userName);

        if (newUser) {
          // If the user is new, navigate to a form to collect additional details
          navigate("/additional-info", { state: { userName } });
          return { success: true, newUser: true };
        } else {
          navigate("/plans");
        }

        return { success: true, newUser: false, userName };
      } else {
        console.error("Error from backend:", response.data);
        return {
          success: false,
          message: response.data.message || "Google signup failed",
        };
      }
    } catch (error) {
      console.error("Error:", error);
      return { success: false, message: "An unexpected error occurred" };
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const { credential } = response;
      const result = await sendGoogleDataToBackend(credential);

      console.log(result);

      if (result.success) {
        // window.location.href = "/plans"; // Redirect to the dashboard
      } else {
        ErrorMessage(result.message); // Display error message if signup fails
      }
    } catch (error) {
      console.error("Error during Google signup:", error);
      ErrorMessage("Google signup failed. Please try again.");
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);

    // Customize error messages based on the error type
    if (error.error === "popup_closed_by_user") {
      setError(
        "The login popup was closed before completing the login. Please try again."
      );
    } else if (error.error === "idpiframe_initialization_failed") {
      setError(
        "Google login is not supported in your browser. Please try a different one."
      );
    } else {
      setError("Google login failed. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <div className="login-form">
          <div className="header">
            <h1 className="title">Log In</h1>
          </div>
          <div className="social-buttons">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} // Replace with your actual client ID
              buttonText="Login with Google"
              onSuccess={handleGoogleLoginSuccess}
              onFailure={handleGoogleLoginFailure}
              cookiePolicy="single_host_origin"
            />
          </div>
          <div className="divider">
            <hr className="divider-line" />
            <span className="divider-text">or log in with</span>
            <hr className="divider-line" />
          </div>
          <form noValidate onSubmit={handleSubmit} className="login-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  className="form-input"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="password-wrapper">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-input"
                  />
                  <button
                    type="button"
                    className="toggle-password"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    aria-label="Toggle password visibility"
                  >
                    {passwordVisible ? "👀" : "🫣"}
                  </button>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="checkbox-group">
                <ColoredCheckbox
                  label="Remember this Device"
                  color="primary"
                  name="remember"
                />
                <Link to="/forgot-password" className="forgot-password-link">
                  Forgot Password?
                </Link>
              </div>
            </div>
            <div className="form-group">
              {error && <div className="error-message">{error}</div>}
              <ButtonWithIcon
                type="submit"
                className="submit-button"
                text="Login"
              />
            </div>
            <div className="sign-up">
              <h1 className="new-to-ourdept">New to OurDept?</h1>
              <Link to="/sign-up" className="signup-link">
                Create an account
              </Link>
            </div>
          </form>
        </div>
        <div className="welcome-section">
          <h1 className="welcome-title">Welcome to OurDept</h1>
          <p className="welcome-description">
            OurDept helps developers build organized and well-coded dashboards
            full of beautiful and rich modules.
          </p>
          <ButtonWithIcon className="learn-more-button" text="Learn More" />
        </div>
      </GoogleOAuthProvider>
    </div>
  );
}
