import React from "react";
import "./PrivacyPolicy.scss"; // Importing the CSS file for styling

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Your privacy is important to us. This Privacy Policy outlines the
          information we collect, how we use it, and the measures we take to
          protect your personal data.
        </p>
      </section>

      <section>
        <h2>Information Collection</h2>
        <p>
          We collect personal information, including your name, email address,
          billing information, and usage data, when you purchase our products or
          interact with our website. We also collect cookies and browsing data
          to enhance your experience and for analytics purposes.
        </p>
      </section>

      <section>
        <h2>Use of Information</h2>
        <p>
          We use your information to process orders, communicate with you about
          products, provide support, and send relevant marketing communications
          if you opt-in. We do not share or sell your personal information to
          third parties except as required for processing payments or fulfilling
          orders.
        </p>
      </section>

      <section>
        <h2>Security Measures</h2>
        <p>
          We take your privacy seriously and use industry-standard security
          measures to protect your data. All sensitive information, such as
          payment details, is encrypted using secure technology.
        </p>
      </section>

      <section>
        <h2>Data Retention</h2>
        <p>
          We retain your personal information for as long as necessary to
          process your orders or as required by law. You can request to access,
          update, or delete your personal information at any time.
        </p>
      </section>

      <section>
        <h2>Third-Party Links</h2>
        <p>
          Our website may contain links to third-party sites. We are not
          responsible for the privacy practices or content of those websites.
        </p>
      </section>

      <section>
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the effective date will be updated
          accordingly.
        </p>
      </section>

      <hr />

      <h1>Refund and Return Policy</h1>

      <section>
        <p>
          Once the refund is approved, we will process and credit the amount
          within 5-7 business days.
        </p>
      </section>

      <hr />

      <h1>Shipping Policy</h1>

      <section>
        <p>No shipping policy</p>
      </section>

      <footer>
        <p>MANAGED BY - HR Labs</p>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
