import React from "react";
import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import "./ButtonWithIcon.scss";

const ButtonWithIcon = ({
  icon,
  text,
  onClick = () => {},
  className = "",
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={`button-with-icon ${className}`}
      disabled={disabled}
    >
      {icon && <Icon icon={icon} className="button-icon" />}
      {text}
    </button>
  );
};

ButtonWithIcon.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ButtonWithIcon;
