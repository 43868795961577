import React from 'react';
import PropTypes from 'prop-types';
import './ColoredRadio.scss';

const ColoredRadio = ({ label, color, checked, disabled, name, value, onChange }) => {
  return (
    <div className={`colored-radio ${color}`}>
      <label>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span className="radio-label">{label}</span>
      </label>
    </div>
  );
};

ColoredRadio.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ColoredRadio.defaultProps = {
  disabled: false,
};

export default ColoredRadio;
