import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Signup.scss";
import ButtonWithIcon from "../Components/ButtonwithIcon/ButtonWithIcon";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axiosInstance from "../axiosInstance";

// Validation schema with Yup
const validationSchema = Yup.object({
  companyName: Yup.string().required("Company Name is required"),
  numberOfEmployees: Yup.number()
    .required("Number of Employees is required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
  mobileNumber: Yup.string()
    .required("Mobile is required")
    .matches(/^[0-9]{10}$/, "Mobile Number must be 10 digits"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "At least 8 characters")
    .matches(/\d/, "At least one number")
    .matches(/[A-Z]/, "At least one uppercase letter")
    .matches(/[a-z]/, "At least one lowercase letter")
    .matches(/[@#$%^&+*=!]/, "At least one special character (@#$%^&+=!)")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const checkPasswordRequirements = (password) => {
  const requirements = [
    { text: "At least 8 characters", isMet: password.length >= 8 },
    { text: "At least one number", isMet: /\d/.test(password) },
    { text: "At least one uppercase letter", isMet: /[A-Z]/.test(password) },
    { text: "At least one lowercase letter", isMet: /[a-z]/.test(password) },
    {
      text: "At least one special character (@#$%^&+=!)",
      isMet: /[@#$%^*&+=!]/.test(password),
    },
  ];
  return requirements;
};

export default function Signup() {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      companyName: "",
      numberOfEmployees: "",
      mobileNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Construct the payload to send to the /user-signup endpoint
        const signupData = {
          email: values.email,
          username: values.email, // You can adjust this as needed
          password: values.password,
          companyName: values.companyName,
          companyStrength: values.numberOfEmployees,
          officialEmail: values.email,
          role: "User", // Adjust based on the role you want to assign
        };

        const response = await axiosInstance.post(
          `${process.env.REACT_APP_BACKEND_URL}/user-signup`,
          signupData
        );

        if (response.status === 200) {
          const { jwtToken, userName, companyName } = response.data.data;

          // Store jwtToken, userName, and companyName in sessionStorage
          sessionStorage.setItem("jwtToken", jwtToken);
          sessionStorage.setItem("userName", userName);
          sessionStorage.setItem("companyName", companyName);

          navigate("/plans");
        } else {
          setErrorMessage("Signup failed, please try again.");
        }
      } catch (error) {
        console.error("Error during signup:", error);
        setErrorMessage("Signup failed. Please try again.");
      }
    },
  });

  const passwordRequirements = checkPasswordRequirements(
    formik.values.password
  );

  const sendGoogleDataToBackend = async (tokenId) => {
    try {
      // Send tokenId as part of the POST request body
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
        { tokenId }
      );

      // Handle successful response
      if (response.status === 200) {
        const { newUser, jwtToken, userName } = response.data.data;

        console.log("Google signup successful", response.data);

        // Store jwtToken and userName in sessionStorage
        sessionStorage.setItem("jwtToken", jwtToken);
        sessionStorage.setItem("userName", userName);

        if (newUser) {
          // If the user is new, navigate to a form to collect additional details
          navigate("/additional-info", { state: { userName } });
          return { success: true, newUser: true };
        } else {
          navigate("/plans");
        }

        return { success: true, newUser: false, userName };
      } else {
        console.error("Error from backend:", response.data);
        return {
          success: false,
          message: response.data.message || "Google signup failed",
        };
      }
    } catch (error) {
      console.error("Error:", error);
      return { success: false, message: "An unexpected error occurred" };
    }
  };

  const handleGoogleSignupSuccess = async (response) => {
    try {
      const { credential } = response;
      const result = await sendGoogleDataToBackend(credential);

      console.log(result);

      if (result.success) {
        // window.location.href = "/plans"; // Redirect to the dashboard
      } else {
        setErrorMessage(result.message); // Display error message if signup fails
      }
    } catch (error) {
      console.error("Error during Google signup:", error);
      setErrorMessage("Google signup failed. Please try again.");
    }
  };

  const handleGoogleSignupFailure = (error) => {
    if (error.error === "popup_closed_by_user") {
      console.warn(
        "User closed the popup before completing the login process."
      );
    } else {
      console.error("Google login failed:", error);
      setErrorMessage("Google login failed. Please try again.");
    }
  };

  return (
    <div className="signup-container">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <div className="signup-wrapper">
          <div className="signup-form">
            <div className="form-header">
              <h1 className="title">Sign Up</h1>
            </div>
            <div className="social-buttons">
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} // Replace with your actual client ID
                buttonText="Sign up with Google"
                onSuccess={handleGoogleSignupSuccess}
                onFailure={handleGoogleSignupFailure}
                cookiePolicy="single_host_origin"
              />
            </div>

            <div className="divider">
              <hr className="divider-line" />
              <span className="divider-text">or sign up with</span>
              <hr className="divider-line" />
            </div>
            <form onSubmit={formik.handleSubmit} className="signup-form">
              <div className="form-group">
                <label htmlFor="companyName" className="form-label">
                  Company Name
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  className="form-input"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.companyName && formik.errors.companyName ? (
                  <div className="validation-message">
                    {formik.errors.companyName}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="numberOfEmployees" className="form-label">
                  Number of Employees
                </label>
                <input
                  type="number"
                  id="numberOfEmployees"
                  name="numberOfEmployees"
                  className="form-input"
                  value={formik.values.numberOfEmployees}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.numberOfEmployees &&
                formik.errors.numberOfEmployees ? (
                  <div className="validation-message">
                    {formik.errors.numberOfEmployees}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="mobileNumber" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  className="form-input"
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                  <div className="validation-message">
                    {formik.errors.mobileNumber}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-input"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="validation-message">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-input"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <ul className="password-requirements">
                    {passwordRequirements.map((req, index) => (
                      <li
                        key={index}
                        className={req.isMet ? "valid" : "invalid"}
                      >
                        {req.text}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  className="form-input"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="validation-message">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </div>
              <ButtonWithIcon
                type="submit"
                className="submit-button"
                text="Sign Up"
              >
                Sign Up
              </ButtonWithIcon>
              <div className="signup-footer">
                <h2 className="footer-text">Already have an Account?</h2>
                <Link to="/login" className="footer-link">
                  Log In
                </Link>
              </div>
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
            </form>
          </div>
          <div className="welcome-section">
            <h1 className="welcome-title">Welcome to OurDept</h1>
            <p className="welcome-description">
              OurDept helps developers to build organized and well-coded
              dashboards full of beautiful and rich modules.
            </p>
            <ButtonWithIcon
              className="learn-more-button"
              text="Learn More"
            ></ButtonWithIcon>
          </div>
        </div>
      </GoogleOAuthProvider>
    </div>
  );
}
