import React, { useState, useEffect } from "react";
import "./Plans.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ColoredRadio from "../Components/ColoredRadio/ColoredRadio";
import Coupon from "./Coupon";
import { FaMedal } from "react-icons/fa"; // Import medal icon from react-icons

const Plans = () => {
  const [employeeRanges, setEmployeeRanges] = useState([]);
  const [employeeRange, setEmployeeRange] = useState("");
  const [useSameEmail, setUseSameEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subdomain, setSubdomain] = useState("");
  const [errors, setErrors] = useState({
    subdomain: "",
    email: "",
  });

  // Function definitions moved here
  const getPricing = (plan, employeeRange) => {
    const pricing = {
      Pro: { "0-20": 150, "21-50": 120, "51-100": 100, "101-200": 90 },
      Enterprise: { "0-20": 170, "21-50": 140, "51-100": 120, "101-200": 110 },
    };

    if (!pricing[plan]) {
      return 0;
    }

    if (plan === "Enterprise") {
      return pricing["Pro"][employeeRange] + 20; // Add extra ₹20 per employee for Enterprise.
    }

    return pricing[plan][employeeRange];
  };

  const calculateTotalPrice = (plan, employeeRange, rangeValue) => {
    if (plan === "Freemium") {
      return 0;
    }
    const pricePerEmployee = getPricing(plan, employeeRange);
    return pricePerEmployee * rangeValue;
  };

  useEffect(() => {
    setEmployeeRanges([
      { id: 1, label: "0-20", value: "0-20", rangeValue: 20 },
      { id: 2, label: "21-50", value: "21-50", rangeValue: 50 },
      { id: 3, label: "51-100", value: "51-100", rangeValue: 100 },
      { id: 4, label: "101-200", value: "101-200", rangeValue: 200 },
    ]);
  }, []);

  const handleEmployeeRangeChange = (e) => {
    setEmployeeRange(e.target.value);
  };

  const handleBuyNow = (plan) => {
    if (!subdomain) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subdomain: "Subdomain is required",
      }));
      toast.error("Please fill in all required fields");
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    if (!useSameEmail && !email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
      toast.error("Please provide a valid email");
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    const selectedPlanDetails = {
      planName: plan.mainPlanName,
      perUserPrice: plan.perUserPrice,
      totalPrice: plan.totalPrice,
      modules: plan.moduleDtos,
      employeeRange,
      subdomain,
      email: useSameEmail ? `${subdomain}@ourdept.com` : email,
    };

    setSelectedPlan(selectedPlanDetails);

    // Scroll down to a specific element (for example, a confirmation or next step section)
    window.scrollTo({
      top: document.body.scrollHeight, // Scroll to the bottom of the page
      behavior: "smooth",
    });
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Email regex pattern for basic validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validate email format
    if (emailValue && !emailRegex.test(emailValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address",
      }));
    } else {
      if (errors.email) {
        setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
      }
    }
  };

  const handleSubdomainChange = (e) => {
    setSubdomain(e.target.value);
    if (e.target.value && errors.subdomain) {
      setErrors((prevErrors) => ({ ...prevErrors, subdomain: "" }));
    }
  };

  const checkFeatureAvailability = (planName, featureName) => {
    if (planName === "Freemium") {
      return (
        featureName === "Quick Punch In/Out Menus" ||
        featureName === "Submit Timesheet with Attendance"
      );
    } else if (planName === "Pro") {
      return (
        featureName === "Quick Punch In/Out Menus" ||
        featureName === "Submit Timesheet with Attendance" ||
        featureName === "Manage Leave with Approvals" ||
        featureName === "Leave Reports"
      );
    } else if (planName === "Enterprise") {
      return true;
    }
    return false;
  };

  const renderFilteredPlans = () =>
    [
      {
        mainPlanName: "Freemium",
        totalPrice: 0,
        moduleDtos: [
          {
            moduleName: "Attendance Management",
            moduleDescription:
              "Track and manage employee attendance efficiently.",
            moduleFeatures: [
              {
                featureName: "Quick Punch In/Out Menus",
                featureDescription:
                  "Allow employees to quickly punch in and out from the system.",
              },
              {
                featureName: "Submit Timesheet with Attendance",
                featureDescription:
                  "Enable employees to submit their timesheets along with attendance records.",
              },
              {
                featureName: "Timesheet Submission Restrictions",
                featureDescription:
                  "Provide flexibility to configure restrictions for timesheet submissions.",
              },
            ],
          },
          {
            moduleName: "Leave Management",
            moduleDescription: "Streamline leave requests and approvals.",
            moduleFeatures: [
              {
                featureName: "Manage Leave with Approvals",
                featureDescription:
                  "Facilitate a seamless leave request and approval workflow.",
              },
              {
                featureName: "Fallback Feature",
                featureDescription:
                  "Enable fallback options for leave management in case of emergencies.",
              },
              {
                featureName: "Leave Reports",
                featureDescription:
                  "Generate detailed reports on leave usage and trends.",
              },
              {
                featureName: "Leave Actions",
                featureDescription:
                  "Approve, cancel, reject, or revoke leave requests efficiently.",
              },
            ],
          },
        ],
      },
      {
        mainPlanName: "Pro",
        perUserPrice:
          employeeRange && employeeRanges.length > 0
            ? getPricing("Pro", employeeRange)
            : 0,
        totalPrice:
          employeeRange && employeeRanges.length > 0
            ? calculateTotalPrice(
                "Pro",
                employeeRange,
                employeeRanges.find((range) => range.value === employeeRange)
                  ?.rangeValue
              )
            : 0,
        moduleDtos: [
          {
            moduleName: "Attendance Management",
            moduleDescription:
              "Track and manage employee attendance efficiently.",
            moduleFeatures: [
              {
                featureName: "Quick Punch In/Out Menus",
                featureDescription:
                  "Allow employees to quickly punch in and out from the system.",
              },
              {
                featureName: "Submit Timesheet with Attendance",
                featureDescription:
                  "Enable employees to submit their timesheets along with attendance records.",
              },
              {
                featureName: "Timesheet Submission Restrictions",
                featureDescription:
                  "Provide flexibility to configure restrictions for timesheet submissions.",
              },
            ],
          },
          {
            moduleName: "Leave Management",
            moduleDescription: "Streamline leave requests and approvals.",
            moduleFeatures: [
              {
                featureName: "Manage Leave with Approvals",
                featureDescription:
                  "Facilitate a seamless leave request and approval workflow.",
              },
              {
                featureName: "Fallback Feature",
                featureDescription:
                  "Enable fallback options for leave management in case of emergencies.",
              },
              {
                featureName: "Leave Reports",
                featureDescription:
                  "Generate detailed reports on leave usage and trends.",
              },
              {
                featureName: "Leave Actions",
                featureDescription:
                  "Approve, cancel, reject, or revoke leave requests efficiently.",
              },
            ],
          },
        ],
      },
      {
        mainPlanName: "Enterprise",
        perUserPrice:
          employeeRange && employeeRanges.length > 0
            ? getPricing("Enterprise", employeeRange)
            : 0,
        totalPrice:
          employeeRange && employeeRanges.length > 0
            ? calculateTotalPrice(
                "Enterprise",
                employeeRange,
                employeeRanges.find((range) => range.value === employeeRange)
                  ?.rangeValue
              )
            : 0,
        moduleDtos: [
          {
            moduleName: "Attendance Management",
            moduleDescription:
              "Track and manage employee attendance efficiently.",
            moduleFeatures: [
              {
                featureName: "Quick Punch In/Out Menus",
                featureDescription:
                  "Allow employees to quickly punch in and out from the system.",
              },
              {
                featureName: "Submit Timesheet with Attendance",
                featureDescription:
                  "Enable employees to submit their timesheets along with attendance records.",
              },
              {
                featureName: "Timesheet Submission Restrictions",
                featureDescription:
                  "Provide flexibility to configure restrictions for timesheet submissions.",
              },
            ],
          },
          {
            moduleName: "Leave Management",
            moduleDescription: "Streamline leave requests and approvals.",
            moduleFeatures: [
              {
                featureName: "Manage Leave with Approvals",
                featureDescription:
                  "Facilitate a seamless leave request and approval workflow.",
              },
              {
                featureName: "Fallback Feature",
                featureDescription:
                  "Enable fallback options for leave management in case of emergencies.",
              },
              {
                featureName: "Leave Reports",
                featureDescription:
                  "Generate detailed reports on leave usage and trends.",
              },
              {
                featureName: "Leave Actions",
                featureDescription:
                  "Approve, cancel, reject, or revoke leave requests efficiently.",
              },
            ],
          },
        ],
      },
    ].map((plan) => (
      <div key={plan.mainPlanName} className="plan-card">
        <h3>
          {plan.mainPlanName}
          {plan.mainPlanName === "Pro" || plan.mainPlanName === "Enterprise" ? (
            <FaMedal style={{ color: "gold", marginLeft: "10px" }} />
          ) : null}
        </h3>
        <div className="module-cards">
          {plan.moduleDtos.map(({ moduleName, moduleFeatures }) => (
            <div key={moduleName} className="module-card">
              <h4>{moduleName}</h4>
              {moduleFeatures.map(({ featureName, featureDescription }) => {
                const featureAvailable = checkFeatureAvailability(
                  plan.mainPlanName,
                  featureName
                );
                return (
                  <div
                    key={featureName}
                    className={`${
                      featureAvailable ? "available" : "unavailable"
                    }`}
                  >
                    <p className="feature-status">
                      {featureAvailable ? "✅" : "❌"}
                    </p>
                    <p className="feature-name">{featureName}</p>
                  </div>
                );
              })}
            </div>
          ))}
          <p>
            <strong className="price">
              Price Per User/Month: ₹{plan.perUserPrice}
            </strong>
          </p>
          <p>
            <strong className="price">
              Total Price Per Month: ₹{plan.totalPrice}
            </strong>
          </p>
          <button
            className="buy-now-button"
            onClick={() => handleBuyNow(plan)} // Pass the plan details
          >
            Buy Now
          </button>
        </div>
      </div>
    ));

  return (
    <div className="plans-container">
      <h2>Number of Employees</h2>
      <ToastContainer />
      <div className="employee-ranges">
        {employeeRanges.map((range) => (
          <ColoredRadio
            key={range.id}
            label={range.label}
            value={range.value}
            checked={employeeRange === range.value}
            onChange={handleEmployeeRangeChange}
          />
        ))}
      </div>
      <div className="email-field">
        <div className="sub-domain">
          <h5>
            {subdomain ? `${subdomain}/Ourdept.com ` : "mycompany/Ourdept.com"}
          </h5>
          <input
            type="text"
            value={subdomain}
            onChange={handleSubdomainChange}
            placeholder="Enter Subdomain (e.g., mycompany)"
          />
          {errors.subdomain && <p className="error">{errors.subdomain}</p>}
        </div>
        <div className="email">
          <label htmlFor="useSameEmail">
            <p>Email for communication?</p>
          </label>
          {!useSameEmail && (
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter Email Address"
            />
          )}
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
      </div>
      <div className="plan-features">{renderFilteredPlans()}</div>

      {selectedPlan && (
        <Coupon
          planDetails={selectedPlan}
          onBack={() => setSelectedPlan(null)} // Callback to return to the plans page
        />
      )}
    </div>
  );
};

export default Plans;
