import React from "react";
import "./Home.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
import supportImage from "../Images/SupportImage.jpg"; // Replace with the support team image
import intuitiveImage from "../Images/Intiutive.png";
import TriangleImage from "../Images/Triangle.png";

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>
            Streamline Your Organisation with HRMS from
            <h2 className="ourdept">OurDept</h2>
          </h1>
          <p>
            Boost efficiency, simplify processes, and empower your team with a
            single, integrated platform for all your HR needs.
          </p>
          <div className="hero-buttons">
            <button className="primary-button">Get Started</button>
            <button className="secondary-button">Learn More</button>
          </div>
        </div>
      </section>

      {/* Streamlined Operations Section */}
      <section className="operations-section">
        <h2>Streamlined Operations</h2>
        <div className="operations-content">
          <div>
            <h3>Automated Tasks</h3>
            <p>Reduce manual effort by automating repetitive tasks.</p>
          </div>
          <div>
            <h3>Centralized Data</h3>
            <p>Access all your data in one place, eliminating silos.</p>
          </div>
        </div>
      </section>

      {/* Integrated HR Management Section */}
      <section className="hr-management-section">
        <h2>Integrated HR Management</h2>
        <div className="hr-management-cards">
          <div className="card">
            <h3>Talent Acquisition</h3>
            <p>Streamline recruiting, onboarding, and employee management.</p>
          </div>
          <div className="card">
            <h3>Performance Management</h3>
            <p>Track progress, provide feedback, and foster improvement.</p>
          </div>
          <div className="card">
            <h3>Payroll & Benefits</h3>
            <p>Manage payroll, benefits, and compliance effortlessly.</p>
          </div>
        </div>
      </section>

      {/* Intuitive Dashboard Section */}
      <section className="intuitive-dashboard">
        <div className="intuitive-dashboard__container">
          {/* Left: Image */}
          <img
            className="intuitive-dashboard__image"
            src={intuitiveImage}
            alt="Dashboard"
          />

          {/* Right: Heading and paragraphs */}
          <div className="intuitive-dashboard__content">
            <h2 className="intuitive-dashboard__title">Intuitive Dashboard</h2>

            {/* Real-time Insights */}
            <div className="intuitive-dashboard__item">
              <Icon
                icon="hugeicons:analytics-up"
                className="intuitive-dashboard__icon"
                width="40"
                height="40"
              />
              <div className="intuitive-dashboard__text">
                <h3 className="intuitive-dashboard__subtitle">
                  Real-time Insights
                </h3>
                <p className="intuitive-dashboard__description">
                  Gain actionable insights from your data with intuitive
                  dashboards and reports.
                </p>
              </div>
            </div>

            {/* Key Performance Indicators */}
            <div className="intuitive-dashboard__item">
              <Icon
                icon="eos-icons:performance"
                className="intuitive-dashboard__icon"
                width="40"
                height="40"
              />
              <div className="intuitive-dashboard__text">
                <h3 className="intuitive-dashboard__subtitle">
                  Key Performance Indicators
                </h3>
                <p className="intuitive-dashboard__description">
                  Track crucial metrics and identify areas for improvement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Customizable Reporting Section */}
      <section className="reporting-section">
        <h2>Customizable Reporting</h2>
        <div className="reporting-steps">
          <div className="step">
            <div className="circle">
              <span>1</span>
            </div>
            <h3>Data-Driven Decisions</h3>
            <p>
              Generate customized reports to understand your business
              performance in detail.
            </p>
          </div>
          <div className="step">
            <div className="circle">
              <span>2</span>
            </div>
            <h3>Targeted Insights</h3>
            <p>
              Filter and analyze data based on your specific needs and goals.
            </p>
          </div>
          <div className="step">
            <div className="circle">
              <span>3</span>
            </div>
            <h3>Track Progress</h3>
            <p>
              Monitor key metrics over time and see the impact of your efforts.
            </p>
          </div>
        </div>
      </section>

      {/* Mobile Accessibility Section */}
      <section className="mobile-accessibility-section">
        <h2>Mobile Accessibility</h2>
        <div className="accessibility-content">
          <div className="accessibility-step">
            <div className="step-icon">1</div>
            <h3>Work On the Go</h3>
            <p>
              Access your data and manage your business from anywhere, anytime.
            </p>
          </div>
          <div className="accessibility-step">
            <div className="step-icon">2</div>
            <h3>Stay Connected</h3>
            <p>
              Collaborate with your team and stay up-to-date on important
              information.
            </p>
          </div>
          <div className="accessibility-step">
            <div className="step-icon">3</div>
            <h3>Enhanced Productivity</h3>
            <p>
              Increase efficiency and flexibility with mobile access to key
              features.
            </p>
          </div>
        </div>
      </section>

      {/* Scalable to Grow Section */}
      <section class="scalable-section">
        <h2>Scalable to Grow with You</h2>
        <div class="pyramid-container">
          <img
            src={TriangleImage}
            alt="Scalable Pyramid"
            class="pyramid-image"
          />
        </div>
      </section>

      {/* Dedicated Support Team Section */}
      <section className="support-section">
        <h2>Dedicated Support Team</h2>
        <div className="support-content">
          <div className="support-info">
            <div className="info-block">
              <h3>CSA</h3>
              <p>Availability</p>
              <p>
                Get expert support whenever you need it between (10:00 AM to
                6:00 PM).
              </p>
            </div>
            <div className="info-block">
              <h3>100%</h3>
              <p>Commitment</p>
              <p>
                We are dedicated to your success and provide tailored solutions.
              </p>
            </div>
          </div>
          <div className="support-image">
            <img src={supportImage} alt="Support Team" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
